import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Divider, List, ListItem, Typography } from '@material-ui/core';
import Link from '~components/Link';
import useConversionFramework from '~hooks/useConversionFramework';
import { getNavigationPageLink } from '~utils/common-site-helper';
import { useUserContext } from '~context/UserContext/UserSessionContext';
import ABLogo from './alephbeta-logo-black.inline.svg';
import ABWhiteLogo from './alephbeta-logo-white.inline.svg';
import NewsletterForm from './components/NewsletterForm';
import StayConnected from './components/StayConnected';

const useStyles = makeStyles(theme => ({
  container: props => ({
    backgroundColor: props.isDark ? '#0F0F0F' : '#FAFAFA'
  }),
  root: {
    flexGrow: 1,
    padding: '40px',
    paddingBottom: '14px',
    overflow: 'hidden'
  },
  heading: {
    fontWeight: 700,
    fontSize: '16px',
    marginBottom: 8,
    color: props => (props.isDark ? '#FFFFFF' : '#000000'),
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10
    }
  },
  link: {
    fontSize: '16px',
    color: props => (props.isDark ? '#FFFFFF' : '#000000'),
    '&:hover': {
      textDecoration: 'none',
      color: '#6D6D6D'
    },
    [theme.breakpoints.down('xs')]: {
      lineHeight: '26px'
    }
  },
  listItemRight: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '60px !important'
    }
  },
  abIntroBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    color: props => (props.isDark ? '#FFFFFF' : '#000000'),
    [theme.breakpoints.up('sm')]: {
      maxWidth: '284px',
      gap: '31px'
    }
  },
  newsletterGridItem: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2
    }
  },
  navLinksGridItem: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 1
    },
    [theme.breakpoints.down('xs')]: {
      order: 3
    }
  },
  navLinksContainer: {
    [theme.breakpoints.down('xs')]: {
      rowGap: '20px'
    }
  },
  abIntroGridItem: {
    order: 3,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'end'
    },
    [theme.breakpoints.down('xs')]: {
      order: 1
    }
  },
  divider: {
    background: props => (props.isDark ? '#505050' : '#E3E3E3'),
    [theme.breakpoints.down('xs')]: {
      marginBottom: '40px'
    }
  },
  footerLine: {
    [theme.breakpoints.up('sm')]: {
      padding: '40px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '40px !important'
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  footerLineGrid: {
    justifyContent: 'space-between',
    columnGap: '10px',
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 40,
      paddingRight: 40
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  footerLineLinkGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '40px',
    fontSize: '14px',
    fontWeight: 500,
    columnGap: '16px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'center',
      rowGap: '4px',
      marginTop: 4
    },
    '& > a:hover': {
      textDecoration: 'none',
      color: props => (props.isDark ? '#FFFFFF' : '#6D6D6D')
    },

    '& > *': {
      marginTop: 5,
      color: props => (props.isDark ? '#A7A7A7' : '#000000')
    }
  }
}));

const Footer = props => {
  const { connectLinks, isLoggedIn } = props;
  const { isMarketingSite } = useConversionFramework();
  const { jwtAvailable } = useUserContext().session;
  const classes = useStyles({ isDark: isMarketingSite });

  const marketingSiteAboutPath = jwtAvailable ? '/welcome' : '/';

  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={6} md={3} className={classes.newsletterGridItem}>
            {isLoggedIn ? <StayConnected connectLinks={connectLinks} /> : <NewsletterForm />}
          </Grid>
          <Grid item xs={12} md={6} className={classes.navLinksGridItem}>
            <Grid container spacing={3} className={classes.navLinksContainer}>
              {/* About Us */}
              <Grid item xs={6} sm={3}>
                <Typography component="h4" variant="caption" className={classes.heading}>
                  About Us
                </Typography>
                <List>
                  <ListItem disableGutters dense>
                    <Link
                      to={isMarketingSite ? marketingSiteAboutPath : '/about'}
                      className={classes.link}
                    >
                      About
                    </Link>
                  </ListItem>
                  <ListItem disableGutters dense>
                    <Link to="/impact" className={classes.link}>
                      Impact
                    </Link>
                  </ListItem>
                  <ListItem disableGutters dense>
                    <Link to="/blog" className={classes.link}>
                      Blog
                    </Link>
                  </ListItem>
                </List>
              </Grid>
              {/* Our Team */}
              <Grid item xs={6} sm={3} className={classes.listItemRight}>
                <Typography component="h4" variant="caption" className={classes.heading}>
                  Our Team
                </Typography>
                <List>
                  <ListItem disableGutters dense>
                    <Link to="/team" className={classes.link}>
                      Team
                    </Link>
                  </ListItem>
                  <ListItem disableGutters dense>
                    <Link to="/jobs" className={classes.link}>
                      Careers
                    </Link>
                  </ListItem>
                </List>
              </Grid>

              {/* Get Help */}
              <Grid item xs={6} sm={3}>
                <Typography component="h4" variant="caption" className={classes.heading}>
                  Get Help
                </Typography>
                <List>
                  <ListItem disableGutters dense>
                    <Link to="/faq" className={classes.link}>
                      FAQ
                    </Link>
                  </ListItem>
                  <ListItem disableGutters dense>
                    <Link
                      to={getNavigationPageLink('/donate#contact', isMarketingSite)}
                      className={classes.link}
                    >
                      Contact
                    </Link>
                  </ListItem>
                </List>
              </Grid>

              {/* Support Us */}
              <Grid item xs={6} sm={3} className={classes.listItemRight}>
                <Typography component="h4" variant="caption" className={classes.heading}>
                  Support Us
                </Typography>
                <List>
                  <ListItem disableGutters dense>
                    <Link to="/subscribe-now" className={classes.link}>
                      Subscribe
                    </Link>
                  </ListItem>
                  <ListItem disableGutters dense>
                    <Link to="/education" className={classes.link}>
                      Educators
                    </Link>
                  </ListItem>
                  <ListItem disableGutters dense>
                    <Link
                      to={getNavigationPageLink('/donate', isMarketingSite)}
                      className={classes.link}
                    >
                      Support Us
                    </Link>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.abIntroGridItem}>
            <div className={classes.abIntroBox}>
              {isMarketingSite ? <ABWhiteLogo /> : <ABLogo />}
              <Typography>
                A Nonprofit Media Company helping people closely read the Torah to discover its
                beauty, meaning and relevance
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.footerLine}>
        <Divider className={classes.divider} />
        <Grid container direction="row" alignItems="center" className={classes.footerLineGrid}>
          <Grid item>
            <Typography className={classes.footerLineLinkGroup}>
              <Link to="/privacy">Cookies Policy</Link>
              <Link to="/terms">Legal Terms</Link>
              <Link to="/privacy">Privacy Policy</Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.footerLineLinkGroup}>
              <span>Connect:</span>
              {connectLinks.map(link => (
                <Link key={link.link_title} to={link.link_url} target="_blank">
                  {link.link_title}
                </Link>
              ))}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
Footer.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  connectLinks: PropTypes.arrayOf(
    PropTypes.shape({
      link_url: PropTypes.string,
      link_title: PropTypes.string,
      icon: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string
      })
    })
  )
};

Footer.defaultProps = {
  connectLinks: []
};
export default Footer;
