import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import { useForm } from 'react-hook-form';
import { useCtaRegistration } from '~hooks/useAuth';
import useConversionFramework from '~hooks/useConversionFramework';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 240,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%'
    }
  },
  heading: {
    fontWeight: 700,
    fontSize: '16px',
    marginBottom: 20,
    color: props => (props.isDark ? '#FFFFFF' : '#000000'),
    [theme.breakpoints.down('xs')]: {
      marginBottom: 18
    }
  },
  text: {
    fontSize: '16px',
    color: props => (props.isDark ? '#FFFFFF' : '#000000')
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    border: props => (props.isDark ? '1px solid #A7A7A7' : '1px solid #000000'),
    borderRadius: 7,
    height: '45px',
    width: '236px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '268px'
    }
  },
  emailInputRoot: {
    width: '156px',
    fontSize: 14,
    lineHeight: '14px',
    height: '100%',
    backgroundColor: 'transparent',
    marginLeft: 19,
    color: props => (props.isDark ? '#FFFFFF' : '#000000')
  },
  button: props => ({
    width: '80px',
    fontSize: 14,
    lineHeight: '14px',
    backgroundColor: props.isDark ? '#FFFFFF' : '#000000',
    margin: 'auto',
    marginTop: '-1px',
    marginRight: 0,
    height: '45px',
    whiteSpace: 'nowrap',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 7,
    borderBottomRightRadius: 7,
    textTransform: 'none',
    paddingLeft: '9px',
    color: props.isDark ? '#000000' : '#FFFFFF',
    '&:hover': {
      backgroundColor: props.isDark ? '#ececec' : '#000000',
      color: props.isDark ? '#000000' : '#c5c5c5'
    }
  }),
  errorText: {
    color: theme.palette.error.main
  }
}));

const NewsletterForm = () => {
  const { isMarketingSite } = useConversionFramework();
  const classes = useStyles({ isDark: isMarketingSite });

  const { register, handleSubmit } = useForm();
  const [regState, registerCta] = useCtaRegistration();
  const { isLoading, isError, error } = regState;

  const onSubmit = ({ username }) => {
    // NOTE: MUST be one of the backend/src/models/RegistrationStrategy.ts
    registerCta({ username, strategy: 'one-line-sign-up' });
  };

  return (
    <Box className={classes.root}>
      <Typography component="h4" variant="caption" className={classes.heading}>
        Stay Updated
      </Typography>
      <Typography component="h4" variant="subtitle1" className={classes.text}>
        Stay curious and inspired with weekly AlephBeta emails.
      </Typography>
      <Box width="100%" marginTop="30px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.inputContainer}>
            <InputBase
              placeholder="Your Email"
              type="email"
              name="username"
              required
              inputProps={{ 'aria-label': 'your email' }}
              className={classes.emailInputRoot}
              inputRef={register}
              disabled={isLoading}
            />
            <Button className={classes.button} disableElevation type="submit">
              Sign Up
            </Button>
          </div>
          {isError && <small className={classes.errorText}>{error}</small>}
        </form>
      </Box>
    </Box>
  );
};

export default NewsletterForm;
