import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Link from '~components/Link';
import useConversionFramework from '~hooks/useConversionFramework';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 240,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%'
    }
  },
  heading: {
    fontWeight: 700,
    fontSize: '16px',
    marginBottom: 20,
    color: props => (props.isDark ? '#FFFFFF' : '#000000'),
    [theme.breakpoints.down('xs')]: {
      marginBottom: 18
    }
  },
  text: {
    fontSize: '16px',
    color: props => (props.isDark ? '#FFFFFF' : '#000000')
  },
  socialMediaIcon: {
    '-webkit-filter': props => (props.isDark ? 'invert(1)' : 'unset'),
    filter: props => (props.isDark ? 'invert(1)' : 'unset')
  }
}));

const StayConnected = ({ connectLinks }) => {
  const { isMarketingSite } = useConversionFramework();
  const classes = useStyles({ isDark: isMarketingSite });

  return (
    <Box className={classes.root}>
      <Typography component="h4" variant="caption" className={classes.heading}>
        Stay Connected
      </Typography>
      <Typography component="h4" variant="subtitle1" className={classes.text}>
        Follow Aleph Beta on social media
      </Typography>
      <Box width="100%" marginTop="20px" display="flex" gridGap="15px">
        {connectLinks.map(link => (
          <Link key={link.link_title} to={link.link_url} target="_blank">
            <img
              src={link?.icon?.url}
              alt={link?.icon?.alt || link.link_title}
              className={classes.socialMediaIcon}
            />
          </Link>
        ))}
      </Box>
    </Box>
  );
};

StayConnected.propTypes = {
  connectLinks: PropTypes.arrayOf(
    PropTypes.shape({
      link_url: PropTypes.string,
      link_title: PropTypes.string,
      icon: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string
      })
    })
  )
};

StayConnected.defaultProps = {
  connectLinks: []
};

export default StayConnected;
